import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-wrapper question-type" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_formSearch = _resolveComponent("formSearch")!
  const _component_pagaTable = _resolveComponent("pagaTable")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          span: 6,
          style: {"padding":"10px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tree, {
              style: {"background":"#FAFAFA"},
              "show-line": true,
              "tree-data": _ctx.treeData,
              selectedKeys: _ctx.selectedKeys,
              "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedKeys) = $event)),
              "replace-fields": _ctx.replaceFields
            }, null, 8, ["tree-data", "selectedKeys", "replace-fields"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, {
          span: 18,
          style: {"padding":"10px"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_formSearch, {
                ref: "formSearch",
                formConfig: _ctx.formConfig,
                onSearch: _ctx.handleSearch,
                formBtn: _ctx.formBtn
              }, null, 8, ["formConfig", "onSearch", "formBtn"]),
              _createVNode(_component_pagaTable, {
                ref: "table",
                columns: _ctx.purchaseColumns,
                sourceData: _ctx.getDataApi,
                "row-selection": {
              selectedRowKeys: _ctx.selectedRowKeys,
              onChange: _ctx.onSelectChange,
            },
                "row-key": "userId",
                onChange: _ctx.handleChange,
                downloadApi: _ctx.handleDownload,
                downloadSelectApi: _ctx.downloadSelectApi
              }, {
                headerLeft: _withCtx(() => []),
                _: 1
              }, 8, ["columns", "sourceData", "row-selection", "onChange", "downloadApi", "downloadSelectApi"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}