
import {
  defineComponent,
  PropType,
  reactive,
  toRefs,
  ref,
  h,
  watch,
} from "vue";
import { TreeDataItem } from "ant-design-vue/es/tree/Tree";
import pagaTable from "@/components/pagination-table/index";
import { TransformCellTextProps } from "ant-design-vue/lib/table/interface";
import formSearch from "@/components/page-search/form";
import { departmentTree } from '@/api/system'
import { QuestionRecordControllerStatistics } from '@/api/api'
import { statisticsDownload } from '@/api/downloadApi'
import {blob} from '@/utils/blob'
import { message } from 'ant-design-vue';


const purchaseColumns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row: TransformCellTextProps) => row.index + 1,
  },
  {
    title: "部门",
    dataIndex: "department",
  },
  {
    title: "考生姓名",
    dataIndex: "name",
  },
  {
    title: "答题总数",
    dataIndex: "examinationNum",
  },
  {
    title: "正确题数",
    dataIndex: "qualifiedNum",
  },
  {
    title: "正确率",
    dataIndex: "passRate",
  },
];
export default defineComponent({
  name: "questionType",
  components: {
    pagaTable,
    formSearch,
  },
  setup(props: any) {
    const data = reactive({
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 16 },
        formItemProps: {
          wrapperCol: {
            span: 24,
          },
        },
      },
      formConfig: [
        {
          colProps: {
            span: 8,
          },
          formItemProps: {
            label: "考生姓名：",
          },
          comConfig: {
            tag: "input", // input, select, date-picker
            key: "realName", // 即是插槽也是字段model key
            props: {
              placeholder: "请输入",
            },
          },
        },
      ],
    });
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys;
    };
    const table = ref();
    const params_api = ref()
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        params_api.value = {
          "currentPage": params.pageIndex,
          "pageSize": params.pageSize,
          departmentId:departmentId.value,
          ...formSearch.value.getQuery(),
        }
        QuestionRecordControllerStatistics({ ...params_api.value }).then(res => {
          reslove(res)
        })
      });
    };
    function handleDownload() {
      statisticsDownload({departmentId:departmentId.value,...formSearch.value.getQuery(),"currentPage": 1, "pageSize": 100000, }).then(res => {
        blob(res)
      })
    }
    function downloadSelectApi() {
      if(data.selectedRowKeys.length>0){
        console.log(data.selectedRowKeys);
        message.warning('当前数据为统计数据，无法单独导出，请点击全部导出');
      }else{
        message.warning('请先选择需要下载的内容');
      }
    }
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      console.log(pagination, filter, sorter, currentDataSource);
    };
    const handleClick = (text: string, row: any) => { };
    const formSearch = ref();
    const popupWindow = ref();
    const Title = ref();
    const Time1 = ref();
    const Time2 = ref();
    const updatedTime = ref([]);
    const handleSearch = (formState: any) => {
      // formSearch.value.getQuery();
      // console.log({ ...formSearch.value.getQuery() });
      // console.log("插槽", { ...updatedTime.value });
      table.value.refresh();
    };
    const selectedKeys = ref<string[]>([]);
    const departmentId = ref()
    watch(selectedKeys, () => {
      departmentId.value = selectedKeys.value
      handleSearch({})
    });
    const value = ref<string>("");

    const onSearch = () => {
      console.log("value", value.value);
    };
    const treeData = ref([]);
    departmentTree({}).then(res => {
      treeData.value = res.data
    })
    const replaceFields = {
      children: 'children',
      key: 'departmentId',
      title: 'departmentName',
    };
    return {
      downloadSelectApi,
      handleDownload,
      replaceFields,
      value,
      onSearch,
      treeData,
      selectedKeys,
      Time1,
      Time2,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      Title,
      handleSearch,
      updatedTime,
    };
  },
});
